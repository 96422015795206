import React, { FC } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Routes } from '../constants/Routes';
import Title from '../components/title';
import { Label, Input, TextArea } from '../components/FormElements';

const KontaktPage: FC = () => {
  return (
    <Layout location={Routes.KONTAKT}>
      <SEO title="Kontakt / Schnupperlektionen" />
      <div className="flex flex-col justify-center items-center pt-8" style={{ maxWidth: '100vw' }}>
        <Title className="mb-4 text-center">Kontakt / Schnupperlektionen</Title>
        <div className="place-items-center grid">
          <div className="md:mx-8 mt-8 w-full md:w-1/2 max-w-lg text-gray-700">
            <p className="font-semibold">Für mehr Informationen wende dich an:</p>
            <p className="mt-2">Tanzstudio aha!</p>
            <p>Ana Tajouiti</p>
            <p>Römerstrasse 177</p>
            <p>8404 Winterthur</p>
            <p>052 / 242 21 72</p>
            <a className='underline' href="mailto:info@tanzstudio-aha.ch">info@tanzstudio-aha.ch</a>
          </div>
          {/* <form
            action="https://formspree.io/xqkqwpoy"
            method="POST"
            name="contact"
            className="md:mx-8 mt-12 md:mt-8 w-full md:w-1/2 max-w-lg"
          >
            <div className="flex flex-wrap -mx-3">
              <div className="mb-6 md:mb-0 px-3 w-full md:w-1/2">
                <Label htmlFor="firstname">
                  Vorname
                  <Input name="vorname" id="firstname" type="text" />
                </Label>
              </div>
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <Label>
                  Don’t fill this out: <input name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                </Label>
              </p>
              <div className="mb-6 md:mb-0 px-3 w-full md:w-1/2">
                <Label htmlFor="lastname">
                  Nachname
                  <Input name="nachname" id="lastname" type="text" />
                </Label>
              </div>
            </div>
            <div className="mb-6 md:mb-0 w-full">
              <Label htmlFor="email">
                Email*
                <Input name="email" id="email" type="email" required />
              </Label>
            </div>
            <div className="mb-6 md:mb-0 w-full">
              <Label htmlFor="adresse">
                Adresse
                <Input name="adresse" id="adresse" type="text" />
              </Label>
            </div>
            <div className="mb-6 md:mb-0 w-full">
              <Label htmlFor="betreff">
                Betreff*
                <Input name="betreff" id="betreff" type="text" required />
              </Label>
            </div>
            <div className="mb-6 md:mb-0 w-full">
              <Label htmlFor="nachricht">
                Nachricht*
                <TextArea name="nachricht" id="nachricht" type="textarea" required />
              </Label>
            </div>
            <button type="submit" className="block bg-primary mt-2 px-4 py-2 w-full text-white text-xl uppercase">
              Senden
            </button>
          </form> */}
        </div>
      </div>
    </Layout>
  );
};

export default KontaktPage;
